import image from './image.jpeg';
import './App.css';
import Typewriter from 'typewriter-effect'
import { FaLinkedin } from "react-icons/fa";
import { SiGmail } from "react-icons/si";


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Hi, I’m Joana 👋🏼
        </h1>
        <img src={image} className="Profile-Pic" alt="profile pic"/>
        <strong className="description-style">
        <Typewriter 
          options={{
            strings: ['IT Strategy Consultant', 'Cloud and AI Enthusiast', 
                      'Technology Transformation Facilitator', 'M.Sc. Information Systems', ],
            autoStart: true,
            loop: true,
          }}
        />
        </strong>
        <div class="button-container">
          <a href="https://www.linkedin.com/in/joana-becheva-672b00186/">
            <button className='ButtonToTop'>
              <FaLinkedin />
            </button>
          </a>
          <a href="mailto:joana.becheva@yahoo.com?subject=Contact">
            <button className='ButtonToTop'>
              <SiGmail />
            </button>
          </a>
        </div>
      </header>
    </div> 
  );
}

export default App;
